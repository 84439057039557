import Vue from 'vue'
import store from '@/store/index'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    meta: { requiresAuthentication: true },
    redirect: { name: 'User' },
    children: [
      {
        path: 'fuser',
        name: 'User',
        component: () =>
          import(/* webpackChunkName: "User" */ '../views/User.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'fuser/:id',
        name: 'UserEdit',
        component: () =>
          import(/* webpackChunkName: "UserEdit" */ '../views/UserEdit.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: '/clients',
        name: 'Clients',
        component: () =>
          import(/* webpackChunkName: "Clients" */ '../views/Clients.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'clients/:id',
        name: 'ClientEdit',
        component: () =>
          import(
            /* webpackChunkName: "ClientEdit" */ '../views/ClientEdit.vue'
          ),
        meta: { requiresAuthentication: true }
      },
      {
        path: '/organizations',
        name: 'Organizations',
        component: () =>
          import(/* webpackChunkName: "Organizations" */ '../views/Organizations.vue'),
        meta: { requiresAuthentication: true }
      },
      {
        path: 'organizations/:id',
        name: 'OrganizationEdit',
        component: () =>
          import(
            /* webpackChunkName: "OrganizationEdit" */ '../views/OrganizationEdit.vue'
          ),
        meta: { requiresAuthentication: true }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/unauthorized',
    name: 'UnauthorizedView',
    component: () =>
      import(/* webpackChunkName: "Unauthorized" */ '../views/Unauthorized.vue')
  },
  {
    path: '/404',
    alias: '*',
    name: 'NotFound',
    component: () =>
      import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuthentication)) {
    if (!store.getters.getSessionUser) {
      next({
        name: 'Login',
        params: { sessionExpired: store.getters.getExpiredSession },
        query: { redirect: to.path }
      })
    } else {
      if (to.matched.some((record) => record.meta.requiresAuthorization)) {
        if (
          store.getters.getSessionUser.system_role !== 'Admin' &&
          store.getters.getSessionUser.system_role !== 'HR' &&
          store.getters.getSessionUser.system_role !== 'Security'
        ) {
          if (from.name !== 'UnauthorizedView') {
            next({ name: 'UnauthorizedView' })
          }
        } else {
          next()
        }
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
